import { observable, makeObservable } from 'mobx';
import { LocaleModel } from '@source/data/model';

export interface LocaleStore {
    locales: LocaleModel[];
}

export class LocaleStoreImpl implements LocaleStore {
    public readonly locales: LocaleModel[] = [];

    constructor() {
        makeObservable(this, {
            locales: observable,
        });
    }
}
