import './polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { setupAppCore } from '@source/config';
import { App } from './components/app/app.component';
import { importComponentsStyles } from '@source/style/styles';

const MOUNT_PLACE_ID = 'content';

importComponentsStyles();

setupAppCore().then(() => {
    ReactDOM.render(
        React.createElement(App),
        document.getElementById(MOUNT_PLACE_ID),
    );
});
