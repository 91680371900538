import * as React from 'react';
import { CommonProps } from '@sportlife/core';
import { BurgerWrapper, BurgerLine } from './burger.component.styles';

export type BurgerProps = CommonProps & {
    isActive: boolean;
    onClick?: () => void;
};

export const Burger: React.FC<BurgerProps> = ({
    isActive,
    onClick,
    ...commonProps
}) => {
    return (
        <BurgerWrapper isActive={isActive} onClick={onClick} {...commonProps}>
            <BurgerLine />
            <BurgerLine />
            <BurgerLine />
        </BurgerWrapper>
    );
};

Burger.displayName = 'Burger';
