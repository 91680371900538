import { TranslateUnitData } from '@sportlife/core';
import { AppModules } from '@source/types';
import { APP_CONFIG_KEY, AppConfig } from '@source/config/app.config';

export const i18nLoader = async (
    locale: string,
    key: string,
    context: string,
): Promise<TranslateUnitData> => {
    const appConfig = await AppCore.config.service.loadConfig<AppConfig>({
        key: APP_CONFIG_KEY,
        context: AppModules.Self,
    });
    const res = await fetch(`${appConfig.data.host}/api/locales/${locale}`);
    const resData = await res.json();
    return resData?.data as TranslateUnitData;
};
