import { buildUrl, Path } from '@sportlife/core';

export type LangRoutePath = {
    lang: string;
};

export type ManagerLoginRouteParams = LangRoutePath;

export const managerLogin = new Path<ManagerLoginRouteParams>(
    '/:lang(ua|ru|en)',
    ({ lang }) => buildUrl('/:lang', { pathParams: { lang } }),
);
export type ManagerPanelRouteParams = LangRoutePath;

export const managerPanel = new Path<ManagerPanelRouteParams>(
    '/:lang(ua|ru|en)/panel',
    ({ lang }) => buildUrl('/:lang/panel', { pathParams: { lang } }),
);

export const routes = {
    managerLogin: managerLogin,
    managerPanel: managerPanel,
};
