import * as React from 'react';
import { ChildrenProps } from '@sportlife/core';
import {
    ManagerPageLayoutContent,
    ManagerPageLayoutHeader,
    ManagerPageLayoutHeaderBurgerMenuUserInfo,
    ManagerPageLayoutHeaderExit,
    ManagerPageLayoutHeaderLogo,
    ManagerPageLayoutHeaderUser,
    ManagerPageLayoutHeaderUserInfo,
    ManagerPageLayoutWrapper,
} from '@source/components/layout/manager-page-layout.component.styles';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { AppTypes } from '@source/types';
import { SessionStore } from '@source/store/session.store';
import { SessionService } from '@source/service/session.service';
import {
    Box,
    BreakpointSize,
    Text,
    TextFontSize,
    TextWeight,
    Visibility,
} from '@installments/components';
import { SettingsStore } from '@source/store/settings.store';
import { ThemeProvider } from 'styled-components';
import merge from 'lodash/merge';
import Logo from '@public/img/logo.png';
import { Burger } from '../burger.component';
import { LocaleSelect } from '@source/components/locale-select.component';
import { useHistory } from 'react-router-dom';
import { routes } from '@source/routes';

export type ManagerPageLayoutProps = ChildrenProps;

export const HeaderHeightContext = React.createContext(0);

export const ManagerPageLayout: React.FC<ManagerPageLayoutProps> = withDependencies(
    ({ $injected, children }) => {
        const history = useHistory();
        const headerRef = React.useRef<HTMLDivElement>(null);
        const [headerHeight, setHeaderHeight] = React.useState<number>(0);
        const [isBurgerActive, setIsBurgerActive] = React.useState(false);

        const [
            sessionStore,
            sessionService,
            settingsStore,
        ] = $injected.dependencies as [
            SessionStore,
            SessionService,
            SettingsStore,
        ];

        const { user, theme, settings } = AppCore.useStateObserver(
            () => ({
                user: sessionStore.sessionUser,
                theme: settingsStore.theme,
                settings: settingsStore.settings,
            }),
            [],
        );

        const setHeader = () => {
            if (headerRef?.current?.offsetHeight) {
                setHeaderHeight(headerRef.current.offsetHeight);
            }
        };

        React.useEffect(() => {
            setHeader();
            window.addEventListener('resize', setHeader);

            return () => {
                window.removeEventListener('resize', setHeader);
            };
        }, []);

        React.useEffect(() => {
            if (!isBurgerActive) {
                return;
            }
            const savedHeightBodyStyle = document.body.style.height;
            const savedOverflowBodyStyle = document.body.style.overflow;

            document.body.style.height = '100vh';
            document.body.style.overflow = 'hidden';

            return () => {
                document.body.style.height = savedHeightBodyStyle;
                document.body.style.overflow = savedOverflowBodyStyle;
            };
        }, [isBurgerActive]);

        return (
            <HeaderHeightContext.Provider value={headerHeight}>
                <ThemeProvider
                    theme={(currentTheme) => merge(currentTheme, theme)}
                >
                    <ManagerPageLayoutWrapper>
                        <ManagerPageLayoutContent>
                            <ManagerPageLayoutHeader>
                                <ManagerPageLayoutHeaderLogo
                                    src={settings?.logo?.url ?? Logo}
                                    height="45px"
                                />
                                <Box marginRight="10px">
                                    <LocaleSelect
                                        onChange={(locale) =>
                                            history.push(
                                                routes.managerPanel.build({
                                                    lang: locale,
                                                }),
                                                locale,
                                            )
                                        }
                                    />
                                </Box>

                                <Visibility
                                    hidden={[
                                        BreakpointSize.xs,
                                        BreakpointSize.sm,
                                    ]}
                                >
                                    <ManagerPageLayoutHeaderUserInfo>
                                        <ManagerPageLayoutHeaderUser>
                                            <Text
                                                color="#787878"
                                                fontSize={TextFontSize.XXS}
                                            >
                                                {user.sellerName}
                                            </Text>
                                            <Text
                                                color="#787878"
                                                fontWeight={TextWeight.BOLD}
                                            >
                                                {user.email}
                                            </Text>
                                        </ManagerPageLayoutHeaderUser>
                                        <ManagerPageLayoutHeaderExit
                                            onClick={() =>
                                                sessionService.logout()
                                            }
                                        />
                                    </ManagerPageLayoutHeaderUserInfo>
                                </Visibility>
                                <Visibility
                                    visible={[
                                        BreakpointSize.xs,
                                        BreakpointSize.sm,
                                    ]}
                                >
                                    <Burger
                                        isActive={isBurgerActive}
                                        onClick={() =>
                                            setIsBurgerActive(!isBurgerActive)
                                        }
                                    />
                                </Visibility>
                            </ManagerPageLayoutHeader>

                            <Visibility
                                visible={[BreakpointSize.xs, BreakpointSize.sm]}
                            >
                                {isBurgerActive && (
                                    <ManagerPageLayoutHeaderBurgerMenuUserInfo>
                                        <Box marginBottom="auto" display="flex">
                                            <ManagerPageLayoutHeaderUser>
                                                <Text
                                                    color="#787878"
                                                    fontSize={TextFontSize.XXS}
                                                >
                                                    {user.sellerName}
                                                </Text>
                                                <Text
                                                    color="#787878"
                                                    fontWeight={TextWeight.BOLD}
                                                >
                                                    {user.email}
                                                </Text>
                                            </ManagerPageLayoutHeaderUser>
                                            <ManagerPageLayoutHeaderExit
                                                onClick={() =>
                                                    sessionService.logout()
                                                }
                                            />
                                        </Box>
                                    </ManagerPageLayoutHeaderBurgerMenuUserInfo>
                                )}
                            </Visibility>
                            <Box marginTop="40px">{children}</Box>
                        </ManagerPageLayoutContent>
                    </ManagerPageLayoutWrapper>
                </ThemeProvider>
            </HeaderHeightContext.Provider>
        );
    },
    {
        dependencies: [
            AppTypes.SessionStore,
            AppTypes.SessionService,
            AppTypes.SettingsStore,
        ],
    },
);

ManagerPageLayout.displayName = 'ManagerPageLayout';
