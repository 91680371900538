import { LocaleModel } from '@source/data/model';
import { AppConfig } from '@source/config/app.config';
import { LocaleDto, ServerResponseDto } from '@source/data/dto';
import { LocaleDtoToModelConverter } from '@source/converter/locale-dto-to-model.converter';
import { InternationalizationStore } from '@sportlife/core';

export interface LocaleApi {
    loadLocales(): Promise<LocaleModel[]>;
}

export class LocaleApiImpl implements LocaleApi {
    constructor(
        private _appConfig: AppConfig,
        private _internationalizationStore: InternationalizationStore,
        private _localeConverter: LocaleDtoToModelConverter,
    ) {}

    async loadLocales(): Promise<LocaleModel[]> {
        const url = `${this._appConfig.host}/api/locales/available`;
        const res: Response = await fetch(url, {
            method: 'GET',
        });
        const resData: ServerResponseDto<LocaleDto[]> = await res.json();

        return this._localeConverter.convertArray(resData.data);
    }
}
