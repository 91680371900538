import styled, { css } from 'styled-components';
import {
    BreakpointSize,
    ExitSvg,
    Image,
    media,
} from '@installments/components';

export const ManagerPageLayoutWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    ${({ theme }) => css`
        background-color: ${theme.background.color_1};
        background-image: linear-gradient(
            0deg,
            ${theme.background.color_1} 0%,
            ${theme.background.color_2} 85%
        );
    `};
`;

export const ManagerPageLayoutContent = styled.div`
    width: 100%;
    max-width: 1250px;
    margin: 20px 20px 35px;
    padding: 20px 40px;
    ${({ theme }) => css`
        border-radius: ${theme.body.borderRadius};
    `};
    background-color: #ffffff;
    box-shadow: 0 2px 14px 0 #878889;

    ${media(
        null,
        BreakpointSize.sm,
    )(
        css`
            padding: 10px 5px;
            margin: 0;
            border-radius: 0;
        `,
    )}
`;

export const ManagerPageLayoutHeader = styled.div`
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    ${media(
        null,
        BreakpointSize.sm,
    )(
        css`
            height: inherit;
        `,
    )}
`;

export const ManagerPageLayoutHeaderLogo = styled(Image)`
    height: 45px;
`;

export const ManagerPageLayoutHeaderUser = styled.div`
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center;
`;

export const ManagerPageLayoutHeaderExit = styled(ExitSvg)`
    height: 35px;
    cursor: pointer;
    margin-left: 20px;
`;

export const ManagerPageLayoutHeaderUserInfo = styled.div`
    display: flex;
`;

export const ManagerPageLayoutHeaderBurgerMenuUserInfo = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 100vh;
    width: 100%;
    margin-top: 20px;
`;
