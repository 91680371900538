import * as React from 'react';
import { APP_CONFIG_KEY } from '@source/config/app.config';
import { OrderModel } from '@source/data/model';
import { OrderStatus } from '@source/page/panel-page/components/order-status.component';
import { copyTextToClipboard } from '@source/util/clipboard.utils';
import dayjs from 'dayjs';
import {
    PanelPageRowViewHorizontalInfoBlock,
    PanelPageRowViewOrder,
    PanelPageRowViewVerticalInfoBlock,
    PanelPageRowViewWrapper,
} from '@source/page/panel-page/components/panel-page-row-view.component.styles';
import {
    Box,
    Button,
    ButtonSizes,
    Loader,
    Text,
    TextWeight,
} from '@installments/components';

export type PanelPageRowViewType = {
    data: OrderModel[];
    isLoading: boolean;
};

export const PanelPageRowView: React.FC<PanelPageRowViewType> = ({
    data,
    isLoading,
}) => {
    const [t] = AppCore.useSimpleTranslator(APP_CONFIG_KEY);

    const renderOrderBlock = (order: OrderModel) => {
        return (
            <PanelPageRowViewOrder>
                <PanelPageRowViewHorizontalInfoBlock spaceBetween>
                    <PanelPageRowViewVerticalInfoBlock>
                        <Text fontWeight={TextWeight.BOLD}>
                            {t('front: manager: home: order: code')}
                        </Text>
                        <Text>{order.code}</Text>
                    </PanelPageRowViewVerticalInfoBlock>

                    <PanelPageRowViewVerticalInfoBlock>
                        <OrderStatus type={order.status} />
                    </PanelPageRowViewVerticalInfoBlock>
                </PanelPageRowViewHorizontalInfoBlock>
                <Box size={12} />
                <PanelPageRowViewHorizontalInfoBlock spaceBetween>
                    <PanelPageRowViewVerticalInfoBlock>
                        <Text fontWeight={TextWeight.BOLD}>
                            {t('front: manager: home: order: sum')}
                        </Text>
                        <Text>{order.sum}</Text>
                    </PanelPageRowViewVerticalInfoBlock>
                    <PanelPageRowViewVerticalInfoBlock>
                        <Text fontWeight={TextWeight.BOLD}>
                            {t('front: manager: home: order: date')}
                        </Text>
                        <Text>
                            {dayjs(`${order.createdDate}`).format(
                                'DD.MM.YYYY HH:mm',
                            )}
                        </Text>
                    </PanelPageRowViewVerticalInfoBlock>
                </PanelPageRowViewHorizontalInfoBlock>
                <Box size={12} />
                <PanelPageRowViewVerticalInfoBlock>
                    <Text fontWeight={TextWeight.BOLD}>
                        {t('front: manager: home: order: id')}
                    </Text>
                    <Text>{order.id}</Text>
                </PanelPageRowViewVerticalInfoBlock>
                <Box size={12} />
                <PanelPageRowViewVerticalInfoBlock>
                    <Text fontWeight={TextWeight.BOLD}>
                        {' '}
                        {t('front: manager: home: order: purpose')}
                    </Text>
                    <Text>{order.paymentPurpose}</Text>
                </PanelPageRowViewVerticalInfoBlock>
                <Box size={12} />
                <PanelPageRowViewHorizontalInfoBlock>
                    <Text fontWeight={TextWeight.BOLD}>
                        {t('front: manager: home: order: user')}
                    </Text>
                    <Box>
                        <Text>{order.userEmail}</Text>
                        <Text>{order.userPhone}</Text>
                    </Box>
                </PanelPageRowViewHorizontalInfoBlock>
                <Box size={12} />
                <PanelPageRowViewHorizontalInfoBlock spaceBetween>
                    <Button
                        size={ButtonSizes.s}
                        onClick={() => copyTextToClipboard(order.href)}
                    >
                        {t('front: manager: home: actions: copy')}
                    </Button>
                    <Button
                        size={ButtonSizes.s}
                        onClick={() => window.open(order.href)}
                    >
                        {t('front: manager: home: actions: open')}
                    </Button>
                </PanelPageRowViewHorizontalInfoBlock>
            </PanelPageRowViewOrder>
        );
    };

    return (
        <PanelPageRowViewWrapper>
            {isLoading && <Loader />}
            {!isLoading && data.map((item) => renderOrderBlock(item))}
        </PanelPageRowViewWrapper>
    );
};

PanelPageRowView.displayName = 'PanelPageRowView';
