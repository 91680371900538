import { Converter } from '@sportlife/core';
import { UserDto } from '@source/data/dto/user.dto';
import { UserModel } from '@source/data/model/user.model';

export type UserDtoToUserModelConverter = Converter<UserDto, UserModel>;

export class UserDtoToUserModelConverterImpl
    extends Converter<UserDto, UserModel>
    implements UserDtoToUserModelConverter {
    convert(source: UserDto): UserModel {
        return {
            id: source.id,
            email: source.email,
            role: source.role,
            firstName: source.first_name,
            lastName: source.last_name,
            avatarImageUrl: source.avatar_image_url,
            countryCode: source.country_code,
            sellerName: source.seller_name,
            phone: source.phone,
        } as UserModel;
    }
}
