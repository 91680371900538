import * as React from 'react';
import { Redirect, RedirectProps, Route, RouteProps } from 'react-router';
import { AppTypes } from '@source/types';
import { ChildrenProps } from '@sportlife/core';
import { SessionService } from '@source/service/session.service';
import { UserRole } from '@source/data/model/user.model';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { SessionStore } from '@source/store/session.store';
import { ManagerPageLayout } from '@source/components/layout/manager-page-layout.component';
import { ForbiddenErrorPage } from '@source/page/forbidden-error-page/forbidden-error-page.component';
import { AppEvents } from '@source/events';

export type AuthRouteProps = RouteProps &
    RedirectProps &
    ChildrenProps & {
        roles?: UserRole[];
    };

export const AuthRoute: React.FC<AuthRouteProps> = withDependencies(
    (props) => {
        const [sessionService, sessionStore] = props.$injected.dependencies as [
            SessionService,
            SessionStore,
        ];
        const [user, setUser] = React.useState(null);

        const state = AppCore.useStateObserver(
            () => ({
                isAuthorized: sessionStore.isAuthorized,
                hasRules: sessionService.checkAccess(props.roles),
            }),
            [],
        );

        React.useEffect(() => {
            const fetchUser = async () => {
                const user = await sessionService.fetchUser();
                setUser(user);
                AppCore.eventBus.publish(AppEvents.EndLoader);
            };
            if (!state.isAuthorized) {
                AppCore.eventBus.publish(AppEvents.StartLoader);
                fetchUser();
            }
        }, []);

        return !!user || state.isAuthorized ? (
            <Route
                path={props.path}
                exact={props.exact}
                strict={props.strict}
                location={props.location}
                sensitive={props.sensitive}
                component={props.component}
                render={props.render}
            >
                {state.hasRules ? (
                    props.children
                ) : (
                    <ManagerPageLayout>
                        <ForbiddenErrorPage />
                    </ManagerPageLayout>
                )}
            </Route>
        ) : (
            <Redirect
                path={props.path}
                to={props.to}
                exact={props.exact}
                from={props.from}
                push={props.push}
                strict={props.strict}
            />
        );
    },
    {
        dependencies: [AppTypes.SessionService, AppTypes.SessionStore],
    },
);
