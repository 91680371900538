import * as React from 'react';
import { useForm } from 'react-hook-form';
import {
    LoginPageContent,
    LoginPageContentLocaleSelect,
    LoginPageForm,
    LoginPageHeader,
    LoginPageWrapper,
} from './login-page.component.styles';
import { useHistory } from 'react-router';
import { routes } from '@source/routes';
import {
    AuthorizationException,
    SessionService,
    ValidationException,
} from '@source/service/session.service';
import { AppTypes } from '@source/types';
import { CredentialsValidator } from '@source/validator/credentials.validator';
import { CredentialsModel } from '@source/data/model';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import {
    alertModal,
    Box,
    Button,
    Image,
    Input,
    Text,
    TextFontSize,
} from '@installments/components';
import Logo from '@public/img/logo-white-v.svg';
import { SessionStore } from '@source/store/session.store';
import { AppEvents } from '@source/events';
import { APP_CONFIG_KEY } from '@source/config/app.config';
import { LocaleSelect } from '@source/components/locale-select.component';

export const LoginPage = withDependencies(
    (props) => {
        const history = useHistory();

        const [t] = AppCore.useSimpleTranslator(APP_CONFIG_KEY);
        const { currentLocale } = AppCore.useI18n();

        const [sessionService, sessionStore, credentialsValidator] = props
            .$injected.dependencies as [
            SessionService,
            SessionStore,
            CredentialsValidator,
        ];

        const { isAuthorized } = AppCore.useStateObserver(
            () => ({
                isAuthorized: sessionStore.isAuthorized,
            }),
            [],
        );

        React.useEffect(() => {
            const fetchUser = async () => {
                const user = await sessionService.fetchUser();
                if (!!user) {
                    history.replace(
                        routes.managerPanel.build({ lang: currentLocale }),
                    );
                }
                AppCore.eventBus.publish(AppEvents.EndLoader);
            };
            if (!isAuthorized) {
                AppCore.eventBus.publish(AppEvents.StartLoader);
                fetchUser();
            } else {
                history.replace(
                    routes.managerPanel.build({ lang: currentLocale }),
                );
            }
        }, []);

        const { handleSubmit, register, getValues, errors } = useForm<
            CredentialsModel
        >({
            mode: 'onSubmit',
        });

        const authorize = async () => {
            try {
                const credentials = getValues();
                await sessionService.authorize(credentials);
                history.replace(
                    routes.managerPanel.build({ lang: currentLocale }),
                );
            } catch (err) {
                let errorType: string;
                if (err instanceof ValidationException) {
                    errorType = 'validationError';
                }
                if (err instanceof AuthorizationException) {
                    errorType = 'authorizationError';
                }
                if (errorType) {
                    const modalId = AppCore.modal.open(
                        alertModal({
                            title: t(
                                `front: manager: login: alert: ${errorType}: title`,
                            ),
                            body: t(
                                `front: manager: login: alert: ${errorType}: body`,
                            ),
                            onClose: () => AppCore.modal.close(modalId),
                        }),
                    );
                }
            }
        };

        return (
            <LoginPageWrapper>
                <LoginPageContent>
                    <LoginPageHeader>
                        <LoginPageContentLocaleSelect>
                            <LocaleSelect
                                onChange={(locale) =>
                                    history.push(
                                        routes.managerPanel.build({
                                            lang: locale,
                                        }),
                                        locale,
                                    )
                                }
                            />
                        </LoginPageContentLocaleSelect>
                        <Image src={Logo} width="150px" />
                    </LoginPageHeader>
                    <LoginPageForm>
                        <Box size={12} />
                        <Box textAlign="center">
                            <Text fontSize={TextFontSize.L}>
                                {t('front: manager: login: title')}
                            </Text>
                        </Box>
                        <Box size={24} />
                        <Text fontSize={TextFontSize.XXS}>
                            {t('front: manager: login: description')}
                        </Text>
                        <Box size={12} />
                        <form onSubmit={handleSubmit(authorize)}>
                            <Input
                                name="email"
                                type="email"
                                placeholder={t(
                                    'front: manager: login: fields: email: label',
                                )}
                                ref={register({
                                    validate: (email: string) =>
                                        credentialsValidator.validate(
                                            { email },
                                            'email',
                                        ),
                                })}
                                isError={!!errors.email}
                                errorMessage={t(
                                    `front: manager: login: fields: email: errors: ${errors.email?.message}`,
                                )}
                                autocomplete="email"
                            />
                            <Box size={24} />
                            <Input
                                name="password"
                                type="password"
                                placeholder={t(
                                    'front: manager: login: fields: password: label',
                                )}
                                ref={register({
                                    validate: (password: string) =>
                                        credentialsValidator.validate(
                                            { password },
                                            'password',
                                        ),
                                })}
                                isError={!!errors.password}
                                errorMessage={t(
                                    `front: manager: login: fields: password: errors: ${errors.password?.message}`,
                                )}
                                autocomplete="current-password"
                            />
                            <Box size={24} />
                            <Button type="submit" isFullWidth>
                                {t('front: manager: login: actions: submit')}
                            </Button>
                        </form>
                    </LoginPageForm>
                </LoginPageContent>
            </LoginPageWrapper>
        );
    },
    {
        dependencies: [
            AppTypes.SessionService,
            AppTypes.SessionStore,
            AppTypes.CredentialsValidator,
        ],
    },
);
