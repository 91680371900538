import styled, { css } from 'styled-components';

export const PanelPageRowViewWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const PanelPageRowViewOrder = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 24px;
    border: 1px solid #f2f2f5;
    ${({ theme }) => css`
        border-radius: ${theme.body.borderRadius};
    `};
`;

export const PanelPageRowViewVerticalInfoBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

type PanelPageRowViewHorizontalInfoBlockType = {
    spaceBetween?: boolean;
};

export const PanelPageRowViewHorizontalInfoBlock = styled.div<
    PanelPageRowViewHorizontalInfoBlockType
>`
    display: flex;
    ${({ spaceBetween }) =>
        spaceBetween
            ? css`
                  justify-content: space-between;
              `
            : css`
                  div:first-child {
                      margin-right: 5px;
                  }
              `}
`;

PanelPageRowViewHorizontalInfoBlock.defaultProps = {
    spaceBetween: false,
};
