import { Validator } from '@sportlife/core';
import { CredentialsModel } from '@source/data/model';

export class CredentialsValidator extends Validator<CredentialsModel> {
    constructor() {
        super({
            email: [
                Validator.required(),
                Validator.regexp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                ),
            ],
            password: Validator.required(),
        });
    }
}
