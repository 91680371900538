export const APP_CORE_NAME = 'AppCore';

export enum AppTypes {
    /* Stores */
    SessionStore = 'SessionStore',
    SettingsStore = 'SettingsStore',
    LocaleStore = 'LocaleStore',

    /* Converters */
    MetaDtoToModelConverter = 'MetaDtoToModelConverter',
    UserDtoToUserModelConverter = 'UserDtoToUserModelConverter',
    OrderDtoToModelConverter = 'OrderDtoToModelConverter',
    LocaleDtoToModelConverter = 'LocaleDtoToModelConverter',

    /* APIs */
    AuthApi = 'AuthApi',
    OrderApi = 'OrderApi',
    LocaleApi = 'LocaleApi',

    /* Services */
    SessionService = 'SessionService',
    LocaleService = 'LocaleService',

    /* Validator */
    CredentialsValidator = 'CredentialsValidator',
    NewOrderValidator = 'NewOrderValidator',
}

export enum AppModules {
    Self = 'app',
}
