import * as React from 'react';
import { CommonProps } from '@sportlife/core';
import {
    NewOrderFormLoader,
    NewOrderFormModalBody,
    NewOrderFormModalHeader,
    NewOrderFormModalHeaderTitle,
    NewOrderFormModalWrapper,
    NewOrderFormSuccess,
    NewOrderFormSuccessActions,
    NewOrderFormSuccessIcon,
} from './new-order-form.modal.styles';
import { NewOrderModel } from '@source/data/model';
import { useForm } from 'react-hook-form';
import {
    alertModal,
    Box,
    Button,
    ButtonSizes,
    Input,
    Loader,
    SuccessSvg,
    Text,
    TextFontSize,
    TextWeight,
} from '@installments/components';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { AppTypes } from '@source/types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { NewOrderValidator } from '@source/validator/new-order.validator';
import { OrderApi } from '@source/api/order.api';
import { copyTextToClipboard } from '@source/util/clipboard.utils';
import { APP_CONFIG_KEY } from '@source/config/app.config';

export type NewOrderFormModalProps = CommonProps & {
    isOpen: boolean;
    onClose: () => void;
};
const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ' ',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
};

export const NewOrderFormModal: React.FC<NewOrderFormModalProps> = withDependencies(
    (props) => {
        const [t] = AppCore.useSimpleTranslator(APP_CONFIG_KEY);
        const [newOrderValidator, orderApi] = props.$injected.dependencies as [
            NewOrderValidator,
            OrderApi,
        ];
        const [isLoading, setIsLoading] = React.useState(false);
        const [hrefOrder, setHrefOrder] = React.useState(null);
        const { handleSubmit, register, getValues, errors, reset } = useForm<
            NewOrderModel
        >({
            mode: 'onSubmit',
        });

        const currencyMask = createNumberMask(defaultMaskOptions);

        const createOrder = async () => {
            try {
                setIsLoading(true);
                const values = getValues();
                const url = await orderApi.createOrder(values);
                reset();
                setHrefOrder(url);
            } catch (err) {
                const modalId = AppCore.modal.open(
                    alertModal({
                        title: t(
                            'front: manager: new_order: alert: api_error: title',
                        ),
                        body: err.message,
                        onClose: () => AppCore.modal.close(modalId),
                    }),
                );
            } finally {
                setIsLoading(false);
            }
        };

        const newOrderFormBody = () => (
            <>
                <Text fontSize={TextFontSize.S}>
                    {t('front: manager: new_order: description')}
                </Text>
                <Box size={24} />
                <form onSubmit={handleSubmit(createOrder)}>
                    <Input
                        name="id"
                        type="text"
                        placeholder={t(
                            'front: manager: new_order: fields: id: label',
                        )}
                        ref={register({
                            validate: (id: string) =>
                                newOrderValidator.validate({ id }, 'id'),
                        })}
                        isError={!!errors.id}
                        errorMessage={t(
                            `front: manager: new_order: fields: id: errors: ${errors.id?.message}`,
                        )}
                    />
                    <Box size={24} />
                    <MaskedInput
                        mask={currencyMask}
                        placeholder={t(
                            'front: manager: new_order: fields: sum: label',
                        )}
                        type="text"
                        id="input-sum"
                        name="sum"
                        inputMode="numeric"
                        render={(ref, props) => {
                            return (
                                <Input
                                    ref={(element) => {
                                        ref(element);
                                        register(element, {
                                            validate: (sum: string) =>
                                                newOrderValidator.validate(
                                                    { sum },
                                                    'sum',
                                                ),
                                        });
                                    }}
                                    {...props}
                                    isError={!!errors.sum}
                                    name="sum"
                                    errorMessage={t(
                                        `front: manager: new_order: fields: sum: errors: ${errors.sum?.message}`,
                                    )}
                                />
                            );
                        }}
                    />
                    <Box size={24} />
                    <Input
                        name="paymentPurpose"
                        type="text"
                        placeholder={t(
                            'front: manager: new_order: fields: payment_purpose: label',
                        )}
                        ref={register({
                            validate: (paymentPurpose: string) =>
                                newOrderValidator.validate(
                                    { paymentPurpose },
                                    'paymentPurpose',
                                ),
                        })}
                        isError={!!errors.paymentPurpose}
                        errorMessage={t(
                            `front: manager: new_order: fields: payment_purpose: errors: ${errors.paymentPurpose?.message}`,
                        )}
                    />
                    <Box size={24} />
                    <Button size={ButtonSizes.s} type="submit" isFullWidth>
                        {t('front: manager: new_order: actions: submit')}
                    </Button>
                </form>
            </>
        );

        const loaderBody = () => (
            <NewOrderFormLoader>
                <Loader />
            </NewOrderFormLoader>
        );

        const successBody = () => (
            <NewOrderFormSuccess>
                <NewOrderFormSuccessIcon>
                    <SuccessSvg size="115px" />
                </NewOrderFormSuccessIcon>
                <Box size={24} />
                <Text fontWeight={TextWeight.BOLD}>
                    {t('front: manager: new_order: success: title')}
                </Text>
                <Box size={12} />
                <Text fontSize={TextFontSize.XXS}>{hrefOrder}</Text>
                <Box size={24} />
                <NewOrderFormSuccessActions>
                    <Button
                        size={ButtonSizes.s}
                        style={{ width: '49%' }}
                        onClick={() => copyTextToClipboard(hrefOrder)}
                    >
                        {t('front: manager: new_order: success: actions: copy')}
                    </Button>
                    <Button
                        size={ButtonSizes.s}
                        style={{ width: '49%' }}
                        onClick={() => window.open(hrefOrder)}
                    >
                        {t('front: manager: new_order: success: actions: open')}
                    </Button>
                </NewOrderFormSuccessActions>
                <Box size={12} />
                <NewOrderFormSuccessActions>
                    <Button
                        size={ButtonSizes.s}
                        onClick={() => setHrefOrder(null)}
                        isFullWidth
                    >
                        {t('front: manager: new_order: success: actions: new')}
                    </Button>
                </NewOrderFormSuccessActions>
            </NewOrderFormSuccess>
        );

        return (
            <NewOrderFormModalWrapper
                size="xs"
                id={props.id}
                style={props.style}
                className={props.className}
                show={props.isOpen}
                onHide={props.onClose}
            >
                <NewOrderFormModalHeader>
                    <NewOrderFormModalHeaderTitle>
                        {t('front: manager: new_order: title')}
                    </NewOrderFormModalHeaderTitle>
                </NewOrderFormModalHeader>
                <NewOrderFormModalBody>
                    {isLoading && loaderBody()}
                    {!isLoading &&
                        (!!hrefOrder ? successBody() : newOrderFormBody())}
                </NewOrderFormModalBody>
            </NewOrderFormModalWrapper>
        );
    },
    {
        dependencies: [AppTypes.NewOrderValidator, AppTypes.OrderApi],
    },
);
