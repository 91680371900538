import { Alert } from '@installments/components';

export const copyTextToClipboard = (text: string) => {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(
        function () {
            Alert?.success('Успішно скопійовано!');
        },
        function (err) {
            Alert?.error('Виникла помилка: ', err);
        },
    );
};

const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
        Alert?.success('Успішно скопійовано!');
    } catch (err) {
        Alert.error('Виникла помилка: ', err);
    }

    document?.body.removeChild(textArea);
};
