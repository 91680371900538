import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { GlobalSpinWrapper } from './global-spin.component.styles';
import { Loader } from '@installments/components';

export type GlobalSpinProps = {
    startProcessingEvents: string[];
    endProcessingEvents: string[];
};

export const GlobalSpin: React.FC<GlobalSpinProps> = (props) => {
    const [countOfActiveProcesses, setCountOfActiveProcesses] = React.useState(
        0,
    );

    AppCore.useEvent(
        (event) => {
            const isStartProcessing = props.startProcessingEvents.find(
                (it) => it === event.eventName,
            );
            setCountOfActiveProcesses(
                (count) => count + (isStartProcessing ? 1 : -1),
            );
        },
        [...props.startProcessingEvents, ...props.endProcessingEvents],
    );

    return ReactDOM.createPortal(
        <GlobalSpinWrapper open={!!countOfActiveProcesses}>
            <Loader />
        </GlobalSpinWrapper>,
        document.body,
    );
};
