import { Converter } from '@sportlife/core';
import { OrderDto } from '@source/data/dto';
import { OrderModel } from '@source/data/model';

export type OrderDtoToModelConverter = Converter<OrderDto, OrderModel>;

export class OrderDtoToModelConverterImpl
    extends Converter<OrderDto, OrderModel>
    implements OrderDtoToModelConverter {
    convert(source: OrderDto): OrderModel {
        return {
            id: source.order_id,
            userEmail: source.user_email,
            userPhone: source.user_phone,
            sum: source.order_sum,
            author: source.created_by,
            createdDate: source.created_at,
            code: source.code,
            paymentPurpose: source.payment_purpose,
            status: source.status,
            href: source.href,
        } as OrderModel;
    }
}
