import { Converter } from '@sportlife/core';
import { ServerResponseMetaDto } from '@source/data/dto';
import { ServerResponseMetaModel } from '@source/data/model';

export type MetaDtoToModelConverter = Converter<
    ServerResponseMetaDto,
    ServerResponseMetaModel
>;

export class MetaDtoToModelConverterImpl
    extends Converter<ServerResponseMetaDto, ServerResponseMetaModel>
    implements MetaDtoToModelConverter {
    convert(source: ServerResponseMetaDto): ServerResponseMetaModel {
        return {
            successUrl: source.success_url,
            failUrl: source.fail_url,
            returnUrl: source.return_url,
            ui: source.ui,
            pagination: {
                currentPage: source?.pagination?.current_page,
                prevPage: source?.pagination?.prev_page,
                nextPage: source?.pagination?.next_page,
                totalPages: source?.pagination?.total_pages,
                totalCount: source?.pagination?.total_count,
            },
        } as ServerResponseMetaModel;
    }
}
