import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Layout } from '@source/components/layout/layout.component';
import {
    defaultTheme,
    FontStyle,
    GlobalStyle,
    ResetStyle,
    SvgSprite,
} from '@installments/components';
import { ThemeProvider } from 'styled-components';
import {
    ModalControllerImpl,
    ModuleContext,
    ToastControllerImpl,
} from '@sportlife/core';
import { AppModules } from '@source/types';
import { AppEvents } from '@source/events';
import { GlobalSpin } from '@source/components/layout/global-spin.component';

export const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ModuleContext.Provider value={AppModules.Self}>
                <ThemeProvider theme={{ ...defaultTheme }}>
                    <ModalControllerImpl />
                    <ToastControllerImpl />
                    <SvgSprite />
                    <FontStyle />
                    <ResetStyle />
                    <GlobalStyle />
                    <Layout />
                    <GlobalSpin
                        startProcessingEvents={[AppEvents.StartLoader]}
                        endProcessingEvents={[AppEvents.EndLoader]}
                    />
                </ThemeProvider>
            </ModuleContext.Provider>
        </BrowserRouter>
    );
};

App.displayName = 'App';
