export enum UserRole {
    Manager = 'credit_manager',
    Seller = 'seller_admin',
}

export const ALL_AVAILABLE_USER_ROLES: string[] = Object.values(UserRole);

export type UserModel = {
    id: number;
    email: string;
    role: UserRole;
    firstName: string;
    lastName: string;
    avatarImageUrl: string;
    countryCode: string;
    sellerName: string;
    phone: string;
};
