import {
    createCoreInstance,
    Core,
    CoreConfig,
    setEventBusDevTool,
    CoreTypes,
    InternationalizationStore,
} from '@sportlife/core';
import { AppTypes, AppModules, APP_CORE_NAME } from './types';
import { APP_CONFIG_KEY, AppConfig } from './config/app.config';
import { Locales } from './locales';
import { i18nLoader } from '@source/loader/i18n.loader';
import { AppEvents } from '@source/events';
import { configLoader } from '@source/loader/config.loader';
import {
    MetaDtoToModelConverter,
    MetaDtoToModelConverterImpl,
} from '@source/converter/meta-dto-to-model.converter';
import { SessionStore, SessionStoreImpl } from '@source/store/session.store';
import { UserDtoToUserModelConverterImpl } from './converter/user-dto-to-user-model.converter';
import { CredentialsValidator } from '@source/validator/credentials.validator';
import { AuthApi, AuthApiImpl } from './api/auth.api';
import { SessionServiceImpl } from './service/session.service';
import { SettingsStore, SettingsStoreImpl } from '@source/store/settings.store';
import { OrderApiImpl } from './api/order.api';
import {
    OrderDtoToModelConverter,
    OrderDtoToModelConverterImpl,
} from '@source/converter/order-dto-to-model.converter';
import { NewOrderValidator } from '@source/validator/new-order.validator';
import { LocaleApi, LocaleApiImpl } from './api/locale.api';
import {
    LocaleDtoToModelConverter,
    LocaleDtoToModelConverterImpl,
} from './converter/locale-dto-to-model.converter';
import { LocaleService, LocaleServiceImpl } from './service/locale.service';
import { LocaleStore, LocaleStoreImpl } from '@source/store/locale.store';

export const setupAppCore = async (): Promise<Core> => {
    const config: CoreConfig = {
        name: APP_CORE_NAME,
        translateUnitLoaders: [
            {
                context: AppModules.Self,
                loader: i18nLoader,
            },
        ],
        configLoaders: [
            {
                context: AppModules.Self,
                loader: configLoader,
            },
        ],
    };

    const appCore = await createCoreInstance(config);

    // Register events
    Object.values(AppEvents).forEach((eventName) =>
        appCore.eventBus.registerEvent(eventName),
    );

    // Apis
    const resolveAppConfig = async () => {
        const config = await appCore.config.service.loadConfig<AppConfig>({
            key: APP_CONFIG_KEY,
            context: AppModules.Self,
        });
        return config.data;
    };

    appCore.bottle.factory(AppTypes.OrderApi, async (container) => {
        return new OrderApiImpl(
            await resolveAppConfig(),
            (await container[
                CoreTypes.InternationalizationStore
            ]) as InternationalizationStore,
            (await container[
                AppTypes.OrderDtoToModelConverter
            ]) as OrderDtoToModelConverter,
            (await container[
                AppTypes.MetaDtoToModelConverter
            ]) as MetaDtoToModelConverter,
        );
    });

    appCore.bottle.factory(AppTypes.AuthApi, async (container) => {
        return new AuthApiImpl(
            await resolveAppConfig(),
            (await container[
                CoreTypes.InternationalizationStore
            ]) as InternationalizationStore,
            (await container[
                AppTypes.UserDtoToUserModelConverter
            ]) as UserDtoToUserModelConverterImpl,
            (await container[
                AppTypes.MetaDtoToModelConverter
            ]) as MetaDtoToModelConverter,
        );
    });

    appCore.bottle.factory(AppTypes.LocaleApi, async (container) => {
        const appConfig = await resolveAppConfig();
        return new LocaleApiImpl(
            appConfig,
            await appCore.internationalization.store,
            (await container[
                AppTypes.LocaleDtoToModelConverter
            ]) as LocaleDtoToModelConverter,
        );
    });

    // Converters
    appCore.bottle.factory(
        AppTypes.LocaleDtoToModelConverter,
        async () => new LocaleDtoToModelConverterImpl(),
    );
    appCore.bottle.factory(
        AppTypes.MetaDtoToModelConverter,
        async () => new MetaDtoToModelConverterImpl(),
    );
    appCore.bottle.factory(
        AppTypes.UserDtoToUserModelConverter,
        async () => new UserDtoToUserModelConverterImpl(),
    );
    appCore.bottle.factory(
        AppTypes.OrderDtoToModelConverter,
        async () => new OrderDtoToModelConverterImpl(),
    );

    // Validators
    appCore.bottle.factory(
        AppTypes.CredentialsValidator,
        async () => new CredentialsValidator(),
    );
    appCore.bottle.factory(
        AppTypes.NewOrderValidator,
        async () => new NewOrderValidator(),
    );

    // Services
    appCore.bottle.factory(
        AppTypes.SessionService,
        async (container) =>
            new SessionServiceImpl(
                (await container[AppTypes.SessionStore]) as SessionStore,
                (await container[AppTypes.SettingsStore]) as SettingsStore,
                (await container[AppTypes.AuthApi]) as AuthApi,
                (await container[
                    AppTypes.CredentialsValidator
                ]) as CredentialsValidator,
                (await container[
                    CoreTypes.InternationalizationStore
                ]) as InternationalizationStore,
            ),
    );
    appCore.bottle.factory(
        AppTypes.LocaleService,
        async (container) =>
            new LocaleServiceImpl(
                (await container[AppTypes.LocaleStore]) as LocaleStore,
                (await container[AppTypes.LocaleApi]) as LocaleApi,
            ),
    );

    // Stores
    appCore.bottle.factory(
        AppTypes.SessionStore,
        async () => new SessionStoreImpl(),
    );

    appCore.bottle.factory(
        AppTypes.SettingsStore,
        async () => new SettingsStoreImpl(),
    );
    appCore.bottle.factory(
        AppTypes.LocaleStore,
        async () => new LocaleStoreImpl(),
    );

    // Start setup locales and country TODO: refactor
    const i18nStore = appCore.internationalization.store;
    const localeService = (await appCore.container[
        AppTypes.LocaleService
    ]) as LocaleService;

    const locales = await localeService.loadLocales();

    const localeCodes = locales.map((it) => it.code);
    const preparedLocale = prepareLocale(localeCodes);

    i18nStore.locales = localeCodes;
    i18nStore.currentLocale = preparedLocale;
    i18nStore.defaultLocale = preparedLocale;

    // TODO add env check
    setEventBusDevTool({
        onEvent: (event) =>
            console.log(
                `${event.eventBusName}: ${event.eventName} [${
                    typeof event.data === 'object'
                        ? JSON.parse(event.data)
                        : event.data
                }]`,
            ),
    });

    return appCore;
};

function prepareLocale(availableLocales: string[]) {
    const checkLocal = (lang: string) =>
        !!availableLocales.find((it) => it === lang);
    const langFromUrl = document.location.pathname.split('/')?.[1];

    if (langFromUrl && checkLocal(langFromUrl)) {
        return langFromUrl;
    } else {
        return Locales.Ua;
    }
}
