import { AppConfig } from '@source/config/app.config';
import { UserDto, ServerResponseDto } from '@source/data/dto';
import {
    UserModel,
    CredentialsModel,
    ServerResponseModel,
} from '@source/data/model';
import { UserDtoToUserModelConverter } from '@source/converter/user-dto-to-user-model.converter';
import { AuthorizationException } from '@source/service/session.service';
import { MetaDtoToModelConverter } from '@source/converter/meta-dto-to-model.converter';
import { InternationalizationStore } from '@sportlife/core';

export interface AuthApi {
    authorize(
        credentials: CredentialsModel,
    ): Promise<ServerResponseModel<UserModel>>;
    fetchUser(): Promise<ServerResponseModel<UserModel>>;
    logout(): Promise<void>;
}

export class AuthApiImpl implements AuthApi {
    constructor(
        private _appConfig: AppConfig,
        private _i18nStore: InternationalizationStore,
        private _userConverter?: UserDtoToUserModelConverter,
        private _metaDtoToModelConverter?: MetaDtoToModelConverter,
    ) {}

    async authorize(
        credentials: CredentialsModel,
    ): Promise<ServerResponseModel<UserModel>> {
        const params = new URLSearchParams();
        params.set('email', credentials.email);
        params.set('password', credentials.password);

        const url = `${this._appConfig.host}/api/manager/auth/login`;

        const res: Response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept-Language': this._i18nStore.currentLocale,
            },
            credentials: 'include',
            body: params,
        });
        const resData: ServerResponseDto<UserDto> = await res.json();
        if (res.ok) {
            return {
                data: this._userConverter.convert(resData.data),
                meta: this._metaDtoToModelConverter.convert(resData.meta),
            };
        } else {
            throw new AuthorizationException(resData.errors?.[0]?.detail);
        }
    }

    async fetchUser(): Promise<ServerResponseModel<UserModel>> {
        const url = `${this._appConfig.host}/api/manager/profile`;

        const res: Response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept-Language': this._i18nStore.currentLocale,
            },
            credentials: 'include',
        });
        const resData: ServerResponseDto<UserDto> = await res.json();
        if (res.ok) {
            return {
                data: this._userConverter.convert(resData.data),
                meta: this._metaDtoToModelConverter.convert(resData.meta),
            };
        } else {
            throw new AuthorizationException(resData.errors?.[0]?.detail);
        }
    }

    async logout(): Promise<void> {
        const url = `${this._appConfig.host}/api/manager/auth/logout`;

        await fetch(url, {
            method: 'DELETE',
            headers: {
                'Accept-Language': this._i18nStore.currentLocale,
            },
            credentials: 'include',
        });
    }
}
