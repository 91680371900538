import styled, { css } from 'styled-components';

export const LoginPageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    ${({ theme }) => css`
        background-color: ${theme.background.color_1};
        background-image: linear-gradient(
            0deg,
            ${theme.background.color_1} 0%,
            ${theme.background.color_2} 85%
        );
    `};
`;

export const LoginPageContent = styled.div`
    width: 100%;
    max-width: 570px;
    margin: 20px 0 35px;
    ${({ theme }) => css`
        border-radius: ${theme.body.borderRadius};
    `};
    box-shadow: 0 2px 14px 0 #878889;
`;
export const LoginPageContentLocaleSelect = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const LoginPageForm = styled.div`
    background-color: #ffffff;
    ${({ theme }) => css`
        border-bottom-right-radius: ${theme.body.borderRadius};
        border-bottom-left-radius: ${theme.body.borderRadius};
    `};
    width: 100%;
    padding: 50px;
`;

export const LoginPageHeader = styled.div`
    width: 100%;
    ${({ theme }) => css`
        border-top-right-radius: ${theme.body.borderRadius};
        border-top-left-radius: ${theme.body.borderRadius};
        background-color: ${theme.palette.Primary};
    `};
    padding: 20px;
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.Primary};
`;
