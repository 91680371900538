import { SessionStore } from '@source/store/session.store';
import { AuthApi } from '@source/api/auth.api';
import {
    UserModel,
    ALL_AVAILABLE_USER_ROLES,
    CredentialsModel,
} from '@source/data/model';
import { Exception, InternationalizationStore } from '@sportlife/core';
import { CredentialsValidator } from '@source/validator/credentials.validator';
import { SettingsStore } from '@source/store/settings.store';
import { routes } from '@source/routes';

export class ValidationException extends Exception {
    constructor(public reason: string[]) {
        super(`Validation error. Reasons: ${reason.join()}`);
    }
}

export class AuthorizationException extends Exception {
    constructor(reason: string) {
        super(`Authorization error. Reason ${reason}`);
    }
}

export interface SessionService {
    authorize(credentials: CredentialsModel): Promise<void>;
    fetchUser(): Promise<UserModel>;
    checkAccess(roles?: string[]): boolean;
    isAuthorize(): boolean;
    logout(): Promise<void>;
}

export class SessionServiceImpl implements SessionService {
    constructor(
        private _sessionStore: SessionStore,
        private _settingsStore: SettingsStore,
        private _authApi: AuthApi,
        private _credentialsValidator: CredentialsValidator,
        private _internationalizationStore: InternationalizationStore,
    ) {}

    async authorize(credentials: CredentialsModel): Promise<void> {
        const validationMessages = this._credentialsValidator.validate(
            credentials,
        );
        if (validationMessages) {
            throw new ValidationException(validationMessages);
        }
        try {
            const {
                data: user,
                meta: { ui },
            } = await this._authApi.authorize(credentials);

            if (this._checkAccess(user)) {
                this._sessionStore.sessionUser = user;
                this._settingsStore.theme = ui?.theme;
                this._settingsStore.settings = ui?.settings;
            }
        } catch (err) {
            throw new AuthorizationException(err);
        }
    }

    async fetchUser(): Promise<UserModel> {
        if (!!this._sessionStore.sessionUser) {
            return this._sessionStore.sessionUser;
        }

        try {
            const {
                data: user,
                meta: { ui },
            } = await this._authApi.fetchUser();

            if (this._checkAccess(user)) {
                this._sessionStore.sessionUser = user;
                this._settingsStore.theme = ui?.theme;
                this._settingsStore.settings = ui?.settings;
                return user;
            }
        } catch (err) {
            console.error(err);
        }
    }

    checkAccess(roles: string[]): boolean {
        return this._checkAccess(this._sessionStore.sessionUser, roles);
    }

    isAuthorize(): boolean {
        return this._sessionStore.isAuthorized;
    }

    async logout(): Promise<void> {
        await this._authApi.logout();
        document.location.href = routes.managerLogin.build({
            lang: this._internationalizationStore.currentLocale,
        });
    }

    private _checkAccess(user: UserModel, roles = ALL_AVAILABLE_USER_ROLES) {
        return roles.findIndex((role) => role === user?.role) >= 0;
    }
}
