import * as React from 'react';
import { APP_CONFIG_KEY } from '@source/config/app.config';
import { Table } from '@installments/components';
import { OrderModel } from '@source/data/model';
import { OrderStatus } from '@source/page/panel-page/components/order-status.component';
import { copyTextToClipboard } from '@source/util/clipboard.utils';
import dayjs from 'dayjs';
import {
    PanelPageTableViewRowActions,
    PanelPageTableViewRowActionsLink,
    PanelPageTableViewWrapper,
} from './panel-page-table-view.component.styles';

const { Column, Cell, HeaderCell } = Table;

export type PanelPageTableViewType = {
    data: OrderModel[];
    isLoading: boolean;
};

export const PanelPageTableView: React.FC<PanelPageTableViewType> = ({
    data,
    isLoading,
}) => {
    const [t] = AppCore.useSimpleTranslator(APP_CONFIG_KEY);

    return (
        <PanelPageTableViewWrapper
            height={420}
            data={data}
            loading={isLoading}
            autoHeight
            wordWrap
        >
            <Column width={150} verticalAlign="middle" align="center">
                <HeaderCell>
                    {t('front: manager: home: order: date')}
                </HeaderCell>
                <Cell>
                    {(rowData) =>
                        dayjs(`${rowData.createdDate}`).format(
                            'DD.MM.YYYY HH:mm',
                        )
                    }
                </Cell>
            </Column>

            <Column width={150} verticalAlign="middle">
                <HeaderCell>{t('front: manager: home: order: id')}</HeaderCell>
                <Cell dataKey="id" />
            </Column>

            <Column width={80} verticalAlign="middle">
                <HeaderCell>
                    {t('front: manager: home: order: code')}
                </HeaderCell>
                <Cell dataKey="code" />
            </Column>

            <Column width={60} verticalAlign="middle">
                <HeaderCell>{t('front: manager: home: order: sum')}</HeaderCell>
                <Cell dataKey="sum" />
            </Column>

            <Column width={250} verticalAlign="middle">
                <HeaderCell>
                    {t('front: manager: home: order: purpose')}
                </HeaderCell>
                <Cell dataKey="paymentPurpose" />
            </Column>
            <Column width={220} verticalAlign="middle">
                <HeaderCell>
                    {t('front: manager: home: order: user')}
                </HeaderCell>
                <Cell>
                    {(rowData) => (
                        <div>
                            <span>{rowData.userEmail}</span>
                            <br />
                            <span>{rowData.userPhone}</span>
                        </div>
                    )}
                </Cell>
            </Column>

            <Column width={140} align="center" verticalAlign="middle">
                <HeaderCell>
                    {t('front: manager: home: order: status')}
                </HeaderCell>
                <Cell>
                    {(rowData) => <OrderStatus type={rowData.status} />}
                </Cell>
            </Column>
            <Column width={120} verticalAlign="middle">
                <HeaderCell />
                <Cell>
                    {(rowData) => {
                        return (
                            <PanelPageTableViewRowActions>
                                <PanelPageTableViewRowActionsLink
                                    onClick={() =>
                                        copyTextToClipboard(rowData.href)
                                    }
                                >
                                    {t('front: manager: home: actions: copy')}
                                </PanelPageTableViewRowActionsLink>
                                <PanelPageTableViewRowActionsLink
                                    onClick={() => window.open(rowData.href)}
                                >
                                    {t('front: manager: home: actions: open')}
                                </PanelPageTableViewRowActionsLink>
                            </PanelPageTableViewRowActions>
                        );
                    }}
                </Cell>
            </Column>
        </PanelPageTableViewWrapper>
    );
};

PanelPageTableView.displayName = 'PanelPageTableView';
