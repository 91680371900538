import * as React from 'react';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import { Select } from '@installments/components';
import { LangRoutePath } from '@source/routes';
import { useParams } from 'react-router-dom';

export type PayPageContentProps = { onChange: (lang: string) => void };

export const LocaleSelect: React.FC<PayPageContentProps> = withDependencies(
    ({ onChange }) => {
        const { lang } = useParams<LangRoutePath>();
        const {
            locales: availableLocale,
            currentLocale,
            setLocale,
        } = AppCore.useI18n();
        const locales = availableLocale?.map((it) => ({
            value: it,
            label: it.toLocaleUpperCase(),
        }));

        const onChangeLocale = (locale) => {
            setLocale(locale);
            onChange(locale);
        };

        React.useEffect(() => {
            if (lang !== currentLocale) {
                setLocale(lang);
            }
        }, [lang, currentLocale]);

        return (
            <Select
                data={locales}
                value={currentLocale}
                onChange={onChangeLocale}
            />
        );
    },
);
