import { observable, computed, makeObservable } from 'mobx';
import { UserModel } from '@source/data/model/user.model';

export interface SessionStore {
    readonly isAuthorized: boolean;
    sessionUser: UserModel;
}

export class SessionStoreImpl implements SessionStore {
    sessionUser: UserModel = null;

    constructor() {
        makeObservable(this, {
            sessionUser: observable,
            isAuthorized: computed,
        });
    }

    get isAuthorized(): boolean {
        return !!this.sessionUser;
    }
}
