import * as React from 'react';
import { ForbiddenErrorPageWrapper } from './forbidden-error-page.component.styles';
import { Text, TextFontSize, TextWeight } from '@installments/components';
import { APP_CONFIG_KEY } from '@source/config/app.config';

export const ForbiddenErrorPage = () => {
    const [t] = AppCore.useSimpleTranslator(APP_CONFIG_KEY);
    return (
        <ForbiddenErrorPageWrapper>
            <Text fontWeight={TextWeight.BOLD} fontSize="150px">
                403
            </Text>
            <Text fontWeight={TextWeight.BOLD} fontSize={TextFontSize.L}>
                {t('forbidden.description')}
            </Text>
        </ForbiddenErrorPageWrapper>
    );
};
