import { OrderDto, ServerResponseDto } from '@source/data/dto';
import { AppConfig } from '@source/config/app.config';
import { Exception, InternationalizationStore } from '@sportlife/core';
import { NewOrderModel, OrderModel } from '@source/data/model';
import { ServerResponseModel } from '@source/data/model/server-response.model';
import { MetaDtoToModelConverter } from '@source/converter/meta-dto-to-model.converter';
import { OrderDtoToModelConverter } from '@source/converter/order-dto-to-model.converter';

export const countPerPageKey = 'countPerPage';

export class OrderException extends Exception {
    constructor(reason: string) {
        super(reason);
    }
}

export interface OrderApi {
    fetchOrders(pageNumber: number): Promise<ServerResponseModel<OrderModel[]>>;
    createOrder(order: NewOrderModel): Promise<string>;
}

export class OrderApiImpl implements OrderApi {
    constructor(
        private _appConfig: AppConfig,
        private _i18nStore: InternationalizationStore,
        private _orderDtoToModelConverter: OrderDtoToModelConverter,
        private _metaDtoToModelConverter: MetaDtoToModelConverter,
    ) {}

    async fetchOrders(
        pageNumber: number,
    ): Promise<ServerResponseModel<OrderModel[]>> {
        const url = `${
            this._appConfig.host
        }/api/manager/orders?page_number=${pageNumber.toString()}&page_size=${
            window.localStorage.getItem(countPerPageKey) || '10'
        }`;

        const res: Response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept-Language': this._i18nStore.currentLocale,
            },
            credentials: 'include',
        });
        const resData: ServerResponseDto<OrderDto[]> = await res.json();

        if (!res.ok) {
            return {
                meta: resData.meta
                    ? this._metaDtoToModelConverter.convert(resData.meta)
                    : null,
                errors: resData.errors,
            };
        }
        return {
            data: this._orderDtoToModelConverter.convertArray(resData.data),
            meta: this._metaDtoToModelConverter.convert(resData.meta),
        };
    }

    async createOrder(order: NewOrderModel): Promise<string> {
        const url = `${this._appConfig.host}/api/manager/orders`;

        const params = new URLSearchParams();
        params.set('order_id', order.id);
        params.set('payment_purpose', order.paymentPurpose);
        params.set('order_sum', order.sum.replace(' ', ''));

        const res: Response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept-Language': this._i18nStore.currentLocale,
            },
            credentials: 'include',
            body: params,
        });
        const resData: ServerResponseDto<OrderDto> = await res.json();

        if (!res.ok) {
            throw new OrderException(resData.errors[0].detail);
        }

        return resData?.data?.href;
    }
}
