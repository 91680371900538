import styled from 'styled-components';
import { Table } from '@installments/components';

export const PanelPageTableViewWrapper = styled(Table)`
    border-radius: ${({ theme }) => theme.body.borderRadius};
    border: 1px solid #f2f2f5;

    .rs-table-row-header {
        height: 43px !important;
    }

    .rs-table-row-header .rs-table-cell {
        background: ${({ theme }) => theme.button.background} !important;
        color: ${({ theme }) => theme.button.color} !important;
        height: 43px !important;
    }

    .rs-table-cell-header .rs-table-cell-content {
        font-size: 14px;
        font-weight: bold;
    }
`;

export const PanelPageTableViewRowActions = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PanelPageTableViewRowActionsLink = styled.span`
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 5px;
`;
