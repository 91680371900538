import styled from 'styled-components';

export const NotFoundErrorPageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: ${({ theme }) => theme.background.color_1};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;
