import { Validator } from '@sportlife/core';
import { NewOrderModel } from '@source/data/model';

export class NewOrderValidator extends Validator<NewOrderModel> {
    constructor() {
        super({
            id: [
                Validator.required(),
                Validator.max(50),
                Validator.regexp(/^[a-zA-Zа-яА-ЯІіЇїЄєҐґ0-9_-]+$/),
            ],
            sum: Validator.required(),
            paymentPurpose: [
                Validator.required(),
                Validator.max(100),
                Validator.regexp(/^[a-zA-Zа-яА-ЯІіЇїЄєҐґ0-9 .,_-]+$/),
            ],
        });
    }
}
