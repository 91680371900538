import styled from 'styled-components';

export type GlobalSpinWrapperProps = {
    open: boolean;
};

export const GlobalSpinWrapper = styled.div<GlobalSpinWrapperProps>`
    z-index: 1000 !important;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    background-color: #f1f1f1;
    position: absolute;
    top: 0;
`;
