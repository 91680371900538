import { Converter } from '@sportlife/core';
import { LocaleDto } from '@source/data/dto';
import { LocaleModel } from '@source/data/model';

export type LocaleDtoToModelConverter = Converter<LocaleDto, LocaleModel>;

export class LocaleDtoToModelConverterImpl
    extends Converter<LocaleDto, LocaleModel>
    implements LocaleDtoToModelConverter {
    convert(source: LocaleDto): LocaleModel {
        return {
            code: source.code,
            hash: source.hash,
            language: source.language,
        } as LocaleModel;
    }
}
