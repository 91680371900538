import styled, { css } from 'styled-components';

export type BurgerWrapperProps = {
    isActive?: boolean;
};

export const BurgerWrapper = styled.div<BurgerWrapperProps>`
    position: relative;
    width: 40px;
    cursor: pointer;

    ${({ isActive }) =>
        isActive &&
        css`
            ${() => BurgerLine} {
                :nth-child(1) {
                    transform: rotate(45deg) translate(18%, 340%);
                }
                :nth-child(2) {
                    opacity: 0;
                }
                :nth-child(3) {
                    top: 0;
                    transform: rotate(-45deg) translate(18%, -290%);
                }
            }
        `}
`;

export const BurgerLine = styled.div`
    position: relative;
    height: 3px;
    width: 100%;
    background: #787878;
    transition: transform 0.2s ease-in, opacity 0.2s ease-in;
    margin-bottom: 8px;
    :last-child {
        margin-bottom: 0;
    }
`;
