import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { routes } from '@source/routes';
import { LayoutWrapper } from './layout.component.styles';
import { PageLayout } from '@source/components/layout/page-layout.component';
import { useAutoScrollToTopWhenChangeLocation } from '@source/hooks/router-scroll-to-top.hook';
import { LoginPage } from '@source/page/login-page/login-page.component';
import { PanelPage } from '@source/page/panel-page/panel-page.component';
import { UserRole } from '@source/data/model';
import { AuthRoute } from '@source/components/layout/auth-route.component';
import { ManagerPageLayout } from '@source/components/layout/manager-page-layout.component';
import { NotFoundErrorPage } from '@source/page/not-found-error-page/not-found-error-page.component';

export const Layout: React.FC = () => {
    const location = useLocation();
    const { currentLocale } = AppCore.useI18n();
    useAutoScrollToTopWhenChangeLocation();

    return (
        <LayoutWrapper>
            <Switch location={location}>
                <AuthRoute
                    exact
                    roles={[UserRole.Manager, UserRole.Seller]}
                    path={routes.managerPanel.path}
                    to={routes.managerLogin.build({ lang: currentLocale })}
                >
                    <ManagerPageLayout>
                        <PanelPage />
                    </ManagerPageLayout>
                </AuthRoute>
                <Route exact path={routes.managerLogin.path}>
                    <PageLayout>
                        <LoginPage />
                    </PageLayout>
                </Route>
                <Redirect
                    exact
                    from="/"
                    to={routes.managerLogin.build({ lang: 'ua' })}
                />
                <Route path="*">
                    <NotFoundErrorPage />
                </Route>
            </Switch>
        </LayoutWrapper>
    );
};
