import * as React from 'react';
import { CommonProps } from '@sportlife/core';
import { Image, Text, TextFontSize } from '@installments/components';
import { NotFoundErrorPageWrapper } from '@source/page/not-found-error-page/not-found-error-page.component.styles';
import LogoHorizontal from '@public/img/logo-color-h.svg';

export type NotFoundErrorPageProps = CommonProps;

export const NotFoundErrorPage: React.FC<NotFoundErrorPageProps> = () => {
    return (
        <NotFoundErrorPageWrapper>
            <Image width="250px" height="auto" src={LogoHorizontal} />
            <Text color="#ffffff" fontSize="150px">
                404
            </Text>
            <Text color="#ffffff" fontSize={TextFontSize.XL}>
                Page not found
            </Text>
        </NotFoundErrorPageWrapper>
    );
};

NotFoundErrorPage.displayName = 'NotFoundErrorPage';
