import styled, { css } from 'styled-components';
import { BreakpointSize, Button, media } from '@installments/components';

export const PanelPageWrapper = styled.div`
    position: relative;
`;

export const PanelPageBody = styled.div`
    position: relative;
`;

export const PanelPageHeader = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
`;

export const PanelPageHeaderRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const PanelPageHeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const PanelPageHeaderActionsButton = styled(Button)`
    margin: 0 5px;
`;

export const PanelPageOrderPagination = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
`;
