import styled from 'styled-components';
import { Modal } from '@installments/components';

export const NewOrderFormModalWrapper = styled(Modal)`
    position: relative;
    max-width: 100%;

    .rs-modal-content {
        border-radius: ${({ theme }) => theme.body.borderRadius};
        padding: 0;
    }
`;

export const NewOrderFormModalHeader = styled(Modal.Header)`
    position: relative;
    background-color: ${({ theme }) => theme.button.background};
    color: ${({ theme }) => theme.button.color};
    padding-left: 20px;
    border-top-left-radius: ${({ theme }) => theme.body.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.body.borderRadius};
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;

    .rs-modal-header-close {
        color: ${({ theme }) => theme.button.color} !important;
    }
`;

export const NewOrderFormModalHeaderTitle = styled.span`
    font-weight: bold;
    font-size: 18px;
`;

export const NewOrderFormModalBody = styled(Modal.Body)`
    padding: 0 40px 30px;
`;

export const NewOrderFormLoader = styled.div`
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NewOrderFormSuccess = styled.div`
    position: relative;
`;

export const NewOrderFormSuccessIcon = styled.div`
    display: flex;
    justify-content: center;
`;

export const NewOrderFormSuccessActions = styled.div`
    display: flex;
    justify-content: space-between;
`;
