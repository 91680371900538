import * as yaml from 'js-yaml';
import { ConfigData, TranslateUnitData } from '@sportlife/core';
import { AppModules } from '@source/types';

import AppConfig from '@public/config/app.yml';

const prepareResourcePaths = (context: string, key: string) =>
    ({
        [AppModules.Self]: {
            app: AppConfig,
        },
    }[context]?.[key]);

export const configLoader = async (
    key: string,
    context: string,
): Promise<ConfigData> => {
    const res = await fetch(prepareResourcePaths(context, key));
    return yaml.safeLoad(await res.text()) as TranslateUnitData;
};
