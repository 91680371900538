import { observable, makeObservable } from 'mobx';
import { ComponentsTheme } from '@installments/components';
import { ServerResponseMetaUISettingsModel } from '@source/data/model';

export interface SettingsStore {
    theme: ComponentsTheme;
    settings: ServerResponseMetaUISettingsModel;
}

export class SettingsStoreImpl implements SettingsStore {
    theme: ComponentsTheme = null;
    settings: ServerResponseMetaUISettingsModel = null;

    constructor() {
        makeObservable(this, {
            theme: observable,
            settings: observable,
        });
    }
}
