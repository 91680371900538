import * as React from 'react';
import { CommonProps, CoreTypes, ModalController } from '@sportlife/core';
import { withDependencies } from '@source/hocs/dependencies.hoc';
import {
    PanelPageBody,
    PanelPageHeader,
    PanelPageHeaderActions,
    PanelPageHeaderActionsButton,
    PanelPageHeaderRow,
    PanelPageOrderPagination,
    PanelPageWrapper,
} from '@source/page/panel-page/panel-page.component.styles';
import {
    Box,
    BreakpointSize,
    ButtonSizes,
    Pagination,
    Select,
    Text,
    TextWeight,
    Visibility,
    PlusSvg,
    SyncSvg,
    ComponentsTheme,
} from '@installments/components';
import {
    OrderModel,
    ServerResponseMetaPaginationModel,
} from '@source/data/model';
import { AppTypes } from '@source/types';
import { countPerPageKey, OrderApi } from '@source/api/order.api';
import { NewOrderFormModal } from '@source/modal/new-order-form.modal';
import { APP_CONFIG_KEY } from '@source/config/app.config';
import { PanelPageTableView } from '@source/page/panel-page/components/panel-page-table-view.component';
import { useTheme } from 'styled-components';
import { PanelPageRowView } from '@source/page/panel-page/components/panel-page-row-view.component';

const countPerPageList = [
    { label: '10', value: '10' },
    { label: '25', value: '25' },
    { label: '50', value: '50' },
    { label: '75', value: '75' },
    { label: '100', value: '100' },
];

export type PanelPageProps = CommonProps;

export const PanelPage = withDependencies<PanelPageProps>(
    ({ $injected, ...commonProps }) => {
        const [t] = AppCore.useSimpleTranslator(APP_CONFIG_KEY);
        const theme = useTheme() as ComponentsTheme;
        const [orderApi, modalController] = $injected.dependencies as [
            OrderApi,
            ModalController,
        ];
        const [data, setData] = React.useState<OrderModel[]>([]);
        const [pagination, setPagination] = React.useState<
            ServerResponseMetaPaginationModel
        >({ currentPage: 1 });
        const [pageNumber, setPageNumber] = React.useState(1);
        const [isLoading, setIsLoading] = React.useState(false);
        const [countPerPage, setCountPerPage] = React.useState(
            window.localStorage.getItem(countPerPageKey) || '10',
        );

        const onChangeCountPerPage = (value) => {
            setCountPerPage(value);
            window.localStorage.setItem(countPerPageKey, value);
            fetchOrders();
        };

        const newOrderModalOpen = () => {
            const modalId = modalController.open(
                <NewOrderFormModal
                    isOpen
                    onClose={() => modalController.close(modalId)}
                />,
            );
        };

        const fetchOrders = () => {
            const fetchData = async () => {
                const data = await orderApi.fetchOrders(pageNumber);
                setData(data?.data);
                setPagination(data?.meta?.pagination);
                setIsLoading(false);
            };
            setIsLoading(true);
            fetchData();
        };

        React.useEffect(() => {
            fetchOrders();
        }, [pageNumber]);

        return (
            <PanelPageWrapper {...commonProps}>
                <PanelPageHeader>
                    <PanelPageHeaderRow>
                        <Visibility
                            visible={[BreakpointSize.xs, BreakpointSize.sm]}
                        >
                            <Text fontWeight={TextWeight.BOLD} fontSize="18px">
                                {t('front: manager: home: title', {
                                    count: pagination.totalCount,
                                })}
                            </Text>
                        </Visibility>
                        <Visibility
                            hidden={[BreakpointSize.xs, BreakpointSize.sm]}
                        >
                            <Text fontWeight={TextWeight.BOLD} fontSize="24px">
                                {t('front: manager: home: title', {
                                    count: pagination.totalCount,
                                })}
                            </Text>
                        </Visibility>
                        <PanelPageHeaderActions>
                            <Visibility
                                visible={[BreakpointSize.xs, BreakpointSize.sm]}
                            >
                                <PanelPageHeaderActionsButton
                                    isSquare
                                    size={ButtonSizes.s}
                                    onClick={newOrderModalOpen}
                                >
                                    <PlusSvg
                                        size="24px"
                                        fill={theme.button.color}
                                    />
                                </PanelPageHeaderActionsButton>
                                <PanelPageHeaderActionsButton
                                    isSquare
                                    size={ButtonSizes.s}
                                    onClick={() =>
                                        pageNumber === 1
                                            ? fetchOrders()
                                            : setPageNumber(1)
                                    }
                                >
                                    <SyncSvg
                                        size="24px"
                                        fill={theme.button.color}
                                    />
                                </PanelPageHeaderActionsButton>
                            </Visibility>
                            <Visibility
                                hidden={[BreakpointSize.xs, BreakpointSize.sm]}
                            >
                                <PanelPageHeaderActionsButton
                                    size={ButtonSizes.s}
                                    onClick={newOrderModalOpen}
                                >
                                    {t('front: manager: home: actions: new')}
                                </PanelPageHeaderActionsButton>
                                <PanelPageHeaderActionsButton
                                    size={ButtonSizes.s}
                                    onClick={() =>
                                        pageNumber === 1
                                            ? fetchOrders()
                                            : setPageNumber(1)
                                    }
                                >
                                    {t('front: manager: home: actions: reload')}
                                </PanelPageHeaderActionsButton>
                            </Visibility>
                        </PanelPageHeaderActions>
                    </PanelPageHeaderRow>
                    <PanelPageHeaderRow>
                        <Box display="flex" alignItems="center">
                            <Text>
                                {t(
                                    'front: manager: home: count_per_page: label',
                                )}
                            </Text>
                            <Box marginLeft="10px">
                                <Select
                                    data={countPerPageList}
                                    value={countPerPage}
                                    onChange={onChangeCountPerPage}
                                />
                            </Box>
                        </Box>
                    </PanelPageHeaderRow>
                </PanelPageHeader>
                <PanelPageBody>
                    <Visibility hidden={[BreakpointSize.xs]}>
                        <PanelPageTableView isLoading={isLoading} data={data} />
                    </Visibility>
                    <Visibility visible={[BreakpointSize.xs]}>
                        <PanelPageRowView isLoading={isLoading} data={data} />
                    </Visibility>
                    <PanelPageOrderPagination>
                        <Pagination
                            ellipsis
                            boundaryLinks
                            pages={pagination.totalPages}
                            maxButtons={3}
                            activePage={pageNumber}
                            onSelect={(page) => setPageNumber(page)}
                            size="sm"
                        />
                    </PanelPageOrderPagination>
                </PanelPageBody>
            </PanelPageWrapper>
        );
    },
    {
        dependencies: [AppTypes.OrderApi, CoreTypes.ModalController],
    },
);
