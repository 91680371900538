import * as React from 'react';
import { ChildrenProps } from '@sportlife/core';

export type PageLayoutProps = ChildrenProps;

export const HeaderHeightContext = React.createContext(0);

export const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
    const headerRef = React.useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = React.useState<number>(0);

    const setHeader = () => {
        if (headerRef?.current?.offsetHeight) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    };

    React.useEffect(() => {
        setHeader();
        window.addEventListener('resize', setHeader);

        return () => {
            window.removeEventListener('resize', setHeader);
        };
    }, []);

    return (
        <HeaderHeightContext.Provider value={headerHeight}>
            {children}
        </HeaderHeightContext.Provider>
    );
};

PageLayout.displayName = 'PageLayout';
