import * as React from 'react';
import { APP_CONFIG_KEY } from '@source/config/app.config';
import { Tag } from '@installments/components';

export type OrderStatusType = {
    type: string;
};

export const OrderStatus: React.FC<OrderStatusType> = ({ type }) => {
    const [t] = AppCore.useSimpleTranslator(APP_CONFIG_KEY);

    switch (type) {
        case 'created':
            return (
                <Tag background="#F4A460">
                    {t('front: manager: home: status: created')}
                </Tag>
            );
        case 'pending':
            return (
                <Tag background="#F4A460">
                    {t('front: manager: home: status: pending')}
                </Tag>
            );
        case 'approved':
            return (
                <Tag background="#3CB371">
                    {t('front: manager: home: status: approved')}
                </Tag>
            );
        case 'failed':
            return (
                <Tag background="#F08080">
                    {t('front: manager: home: status: failed')}
                </Tag>
            );
        case 'canceled':
            return (
                <Tag background="#87CEEB">
                    {t('front: manager: home: status: canceled')}
                </Tag>
            );
        case 'returned':
            return (
                <Tag background="#87CEEB">
                    {t('front: manager: home: status: returned')}
                </Tag>
            );
        case 'error':
            return (
                <Tag background="#FF0000">
                    {t('front: manager: home: status: error')}
                </Tag>
            );
        case 'waiting_for_confirm':
            return (
                <Tag background="#F4A460">
                    {t('front: manager: home: status: waiting_for_confirm')}
                </Tag>
            );
        case 'waiting_for_cancel':
            return (
                <Tag background="#F4A460">
                    {t('front: manager: home: status: waiting_for_cancel')}
                </Tag>
            );
        case 'failed_to_cancel':
            return (
                <Tag background="#F08080">
                    {t('front: manager: home: status: failed_to_cancel')}
                </Tag>
            );
        case 'waiting_for_return':
            return (
                <Tag background="#F4A460">
                    {t('front: manager: home: status: waiting_for_return')}
                </Tag>
            );
        case 'failed_to_return':
            return (
                <Tag background="#F08080">
                    {t('front: manager: home: status: failed_to_return')}
                </Tag>
            );
        case 'failed_to_confirm':
            return (
                <Tag background="#F08080">
                    {t('front: manager: home: status: failed_to_confirm')}
                </Tag>
            );
        default:
            return <Tag>{status}</Tag>;
    }
};

OrderStatus.displayName = 'OrderStatus';
